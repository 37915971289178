import Carousel from '../../components/Carousel/Carousel'

import {
  ProjectsContainer
} from './Projects.styled'


// Projects Variants
const projectsVariant ={
  initial:{
    x:'100vw'
  },
  after:{
    x:0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
    },
  },
  exit:{
    x: '-100vw',
    transition: {
        ease: 'easeInOut',
        duration: .5,
        type: "spring",
    },
  }
}








const Projects = () => {
  return (
    <ProjectsContainer initial={'initial'} animate='after' variants={projectsVariant} exit='exit'>
      <div style={{paddingTop:'20vh',backgroundColor:'transparent'}}> 
      <Carousel/>
      </div>
    </ProjectsContainer>
  )
}

export default Projects