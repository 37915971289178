import styled from 'styled-components'
import {motion} from 'framer-motion'
import {Responsive} from '../../responsive'


export const ContactContainer = styled(motion.div)`
display:flex;
color:white;
position: relative;
height:100vh;
justify-content:center;

`

export const ContactWrapper= styled(motion.div)`
padding-top:20vh;
background-color:transparent;
display:flex;
${Responsive({flexDirection: 'column',paddingTop:'20vh',})};
`


export const ContactLeft= styled(motion.div)`
width:50vw;
${Responsive({display:'none'})};
`
export const ContactRight= styled(motion.div)`
display:flex;
justify-content:center;
width:50vw;
${Responsive({width:'90vw'})};
`
