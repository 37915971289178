import styled from 'styled-components'
import {motion} from 'framer-motion'


export const FormContainer = styled(motion.form)`
    display:flex;
    flex-direction:column;
    background-color:#282c34;
    color:white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    width:400px;
    height:400px;
    padding:0px;
    align-items:center;
`



export const ContactButton = styled(motion.button)`
    background-color:#282c34;
    color:white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding:15px;
    margin-top:35px;
    border:none;
    width:35%;
    margin:20px;

`


export const ContactInput = styled(motion.input)`
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid grey;
    padding:10px;
    outline: 0;
    margin:10px;
    color:white;
  
    
`

export const EmailPic = styled(motion.img)`
width:100px;
height:100px;
  
    
`