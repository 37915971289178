import { Link } from "react-router-dom";

import {
  MenuContainer,
  LogoContainer,
  LinksContainer,
  LogoImg,
  MenuLink
} from "./Menu.styled";

import logo from "../../images/logo.png";

// Logo Variants
const logoVariant ={
  initial:{
    rotate: 270,
  },
  after:{
    rotate: 360,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay:2
    },
  }
}

// Menu Link Variants
const menuLinkVariant ={
  initial:{
    opacity: 0
  },
  after:{
    opacity: 1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay:2
    },
  }
}




const Menu = () => {
  return (
    <MenuContainer>
      <LogoContainer>
        <Link to='/'><LogoImg initial="initial" animate="after" variants={logoVariant} whileHover={{scale:1.2}} whileTap={{scale:1}} src={logo} /></Link>
      </LogoContainer>
      <LinksContainer>
      <Link to="/skills" style={{color:'white',textDecoration: 'none'}}>
          <MenuLink initial="initial" animate="after" variants={menuLinkVariant} whileHover={{scale:1.1}} whileTap={{scale:1}}>MySkills</MenuLink>
        </Link>
        <Link to="/projects" style={{color:'white',textDecoration: 'none'}}>
          <MenuLink initial="initial" animate="after" variants={menuLinkVariant} whileHover={{scale:1.1}} whileTap={{scale:1}}>Projects</MenuLink>
        </Link>
        <Link to="/about" style={{color:'white',textDecoration: 'none'}}>
          <MenuLink initial="initial" animate="after" variants={menuLinkVariant} whileHover={{scale:1.1}} whileTap={{scale:1}}>About Me</MenuLink>
        </Link>
        <Link to="/contact" style={{color:'white',textDecoration: 'none'}}>
          <MenuLink initial="initial" animate="after" variants={menuLinkVariant} whileHover={{scale:1.1}} whileTap={{scale:1}}>Contact Me</MenuLink>
        </Link>
      </LinksContainer>
    </MenuContainer>
  );
};

export default Menu;
