import { ContactContainer, IconsContainer } from "./ContactMenu.styled";
import { motion } from "framer-motion";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import GobiResume from '../../Resume/GobiResume.pdf'

const ContactMenu = () => {
  return (
    <ContactContainer>
      <IconsContainer>
        <motion.a
        style={{color:'white'}}
        href="https://www.linkedin.com/in/gobi-shanthakumar-1749381b1/"
          whileHover={{
            scale: 1.3,
            transition: { duration: 0.3 },
          }}
          whileTap={{ scale: 0.9 }}
        >
          <LinkedInIcon />
        </motion.a>
        <motion.a
        style={{color:'white'}}
        href='https://github.com/GobiShanthan'
          whileHover={{
            scale: 1.3,
            transition: { duration: 0.3 },
          }}
          whileTap={{ scale: 0.9 }}
        >
          <GitHubIcon />
        </motion.a>
        <motion.a
        style={{color:'white'}}
        href={GobiResume}
        download="gobiResume.pdf"
          whileHover={{
            scale: 1.3,
            transition: { duration: 0.3 },
          }}
          whileTap={{ scale: 0.9 }}
        >
          <PictureAsPdfIcon />
        </motion.a>
      </IconsContainer>
    </ContactContainer>
  );
};

export default ContactMenu;
