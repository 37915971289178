import {
  AboutContainer,
  AboutWrapper,
  AboutLeft,
  AboutRight,
  MyPic,
  CardContainer,
  MyInfoP,
  MyInfoTitle
} from './About.styled'

import myPic from '../../images/6.webp'

const infoCardVartiant ={
  initial:{
    x:'-100vw'
  },
  after:{
    x:0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
    },
  },
  exit:{
    y: '-100vh',
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}

const picVartiant ={
  initial:{
    opacity:0
  },
  after:{
    x:0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 4.5,
      delay:1.2,
      ease: "easeInOut",
    },
  },
  exit:{
    x:'100vw',
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}



const About = () => {
  return (
    <AboutContainer>
      <AboutWrapper>
        <AboutLeft>
          <CardContainer variants={infoCardVartiant} initial='initial' animate='after' exit='exit'>
            <MyInfoTitle>About me</MyInfoTitle>
          <MyInfoP variants={picVartiant} initial='initial' animate='after'>
            I am a Software Developer and i specialize in web development and have been working as a freelancer for the past two years. 
            I'm a graduate of General Assembly's Software Engineering Immersive program and have gained
            a strong understanding of various programming languages and frameworks, such as the React, Redux,
            Django, MongoDB, PostgreSQL, Express, Node-Js and many more. Through my work, I've helped clients
            build websites and applications that are both user-friendly and visually appealing. 
            In addition to my technical skills, I'm also experienced in project management and have a keen 
            eye for detail. I'm always looking for new challenges and am passionate about learning new technologies.
          </MyInfoP>
          </CardContainer>
        </AboutLeft>
        <AboutRight>
          <MyPic src={myPic} variants={picVartiant} initial='initial' animate='after' exit='exit'/>
        </AboutRight>
      </AboutWrapper>
    </AboutContainer>
  )
}

export default About