import {
  Container,
  Wrapper,
  Front,
  Tech,
  FrontTitle,
  ButtonsDiv,
  LinkButton,
  ImageMain,
  TechContainer,
  TechTitle,
  TechP,
  SkillsContainer,
  Skill,
} from "./PizzaNeo.styled";

import pizzaPro from "../../images/pizzaPro.webp";

// Home Variants
const homeVariant = {
  initial: {
    y: "120vh",
  },
  after: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
    },
  },
  exit: {
    y: "-140vh",
    transition: {
      ease: "easeInOut",
      duration: 1,
      type: "spring",
    },
  },
};

// Home Variants
const picVariant = {
  initial: {
    opacity: 0,
  },
  after: {
    opacity: 1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay: 2,
    },
  },
  exit: {
    transition: {
      ease: "easeInOut",
      duration: 1,
      type: "spring",
    },
  },
};

// Home Variants
const leftVariant = {
  initial: {
    x: "-100vw",
  },
  after: {
    x: 0,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay: 2,
    },
  },
  exit: {
    transition: {
      ease: "easeInOut",
      duration: 1,
      type: "spring",
    },
  },
};

// Home Variants
const rightVariant = {
  initial: {
    x: "100vw",
  },
  after: {
    x: 0,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay: 2,
    },
  },
  exit: {
    transition: {
      ease: "easeInOut",
      duration: 1,
      type: "spring",
    },
  },
};

// Home Variants
const buttonVariant = {
  initial: {
    scale: 0,
  },
  after: {
    scale: 1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay: 2,
    },
  },
  exit: {
    transition: {
      ease: "easeInOut",
      duration: 1,
      type: "spring",
    },
  },
};

const PizzaNeo = () => {
  return (
    <Container
      initial="initial"
      animate="after"
      variants={homeVariant}
      exit="exit"
    >
      <Wrapper>
        <Front>
          <FrontTitle
            initial="initial"
            animate="after"
            variants={picVariant}
            exit="exit"
          >
            Pizza Neapolitan
          </FrontTitle>
          <ImageMain src={pizzaPro} alt="sv house of fashion" />
          <ButtonsDiv>
            <LinkButton
              initial="initial"
              animate="after"
              variants={buttonVariant}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 1 }}
            >
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="https://pizzaneapolitan.ca"
                target="_blank"
                rel="noopener noreferrer"
              >
                Live Link
              </a>
            </LinkButton>
          </ButtonsDiv>
        </Front>
        <Tech>
          <TechContainer
            initial="initial"
            animate="after"
            variants={leftVariant}
          >
            <TechTitle style={{ paddingTop: "5vh" }}>Tools Used </TechTitle>
            <SkillsContainer>
              <Skill>HTML5</Skill>
              <Skill>CSS3</Skill>
              <Skill>JavaScript</Skill>
              <Skill>REACT</Skill>
              <Skill>REDUX</Skill>
              <Skill>Styled-Components</Skill>
              <Skill>Framer-Motion</Skill>
              <Skill>Express</Skill>
              <Skill>MongoDB</Skill>
              <Skill>Mongoose</Skill>
              <Skill>Axios</Skill>
              <Skill>Stripe</Skill>
              <Skill>FireBase</Skill>
              <Skill>Formik</Skill>
              <Skill>Yup</Skill>
              <Skill>JSON Web Token</Skill>
              <Skill>BcryptJs</Skill>
              <Skill>Socket.IO</Skill>
              <Skill>Nodemailer</Skill>
            </SkillsContainer>
          </TechContainer>
          <TechContainer style={{ paddingBottom: "30vh", paddingTop: "8vh" }}>
            <TechTitle
              initial="initial"
              animate="after"
              variants={picVariant}
              exit="exit"
            >
              Project OverView{" "}
            </TechTitle>
            <TechP initial="initial" animate="after" variants={rightVariant}>
              {" "}
              Is a mobile responsive MERN application that I have worked on as a
              freelancing project for WebFxNow. I created an app that has a
              customized content management system where admin can perform CRUD
              on all items associated with creating pizzas. This system allows
              for greater control and flexibility when it comes to managing the
              content of the app. Additionally, it makes it easier for the admin
              to find the information they need.
            </TechP>
            <TechP initial="initial" animate="after" variants={leftVariant}>
              {" "}
              Prior to this website, I had no idea what Socket.IO was. However,
              thanks to the opportunity to learn about it and implement it on
              this website, I have gained a better understanding of what it is
              and how it works. Essentially, Socket.IO allows for two-way
              communication between the server and the client, which in our case
              is used to check whether pizzas are paid for and ready for pickup.
              Without Socket.IO, we would have to constantly refresh the page to
              check for new updates, which would be very inefficient. Thanks to
              this technology, we can simply wait for the updates to come
              through without having to do anything else.
            </TechP>
            <TechP initial="initial" animate="after" variants={leftVariant}>
              {"  "}
              Working on this website has given me a chance to learn about web
              design. I have also learned how to manage customer expectations
              and keep them happy with the results. This has been a great
              learning experience for me and I am grateful for the opportunity
              to work on such an important part of the business.
            </TechP>
            <TechP initial="initial" animate="after" variants={leftVariant}>
              The best part of the project for me was the styling. I used
              styled-components and Framer motion for the styling which made it
              really fun. I also enjoyed working with the different colors and
              fonts to make everything look cohesive. I really liked working
              with styled-components because I could adjust the styling of my
              components with props. It was easy to work with and I liked how I
              could keep all of my styles in one place. I would definitely
              continue to use styled-components in the future.{" "}
            </TechP>

            <TechP initial="initial" animate="after" variants={rightVariant}>
              {" "}
              There is still work to be done, I would adapt the fat models and
              skinny controllers approach to this project and implement React
              Lazy and Suspense . I learned about this approach in general
              assembly and think it would be a good fit for this project. This
              approach would help keep the project organized.
            </TechP>
          </TechContainer>
        </Tech>
      </Wrapper>
    </Container>
  );
};

export default PizzaNeo;
