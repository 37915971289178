import styled from 'styled-components'
import {motion} from 'framer-motion'

export const SlideContainer = styled(motion.div)`
  
    border-radius:10px;
    text-align:center;
    margin:0%;

`



export const SlideImage = styled(motion.img)`
    width:100px,
    border-radius:10px;
    text-align:center;
    margin:0%;
`

export const SlideButton = styled(motion.button)`
background-color:#282c34;
color:white;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
padding:15px;
border:none;
cursor:pointer;
`