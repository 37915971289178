import styled from 'styled-components'
import {motion} from 'framer-motion'


export const ContactContainer = styled(motion.div)`
display:flex;
color:white;
justify-content:space-between;
position:fixed;
right:20px;
bottom:5vh;
z-index:99;
`

export const IconsContainer = styled(motion.div)`
display:flex;
flex-direction:column;
color:white;
gap:8px;
`

