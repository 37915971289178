import styled from 'styled-components'
import {motion} from 'framer-motion'
import {Responsive} from '../../responsive'

export const AboutContainer = styled(motion.div)`
display:flex;
color:white;
position: relative;
height:100vh;
justify-content:center;
`

export const AboutWrapper= styled(motion.div)`
background-color:transparent;
display:flex;
${Responsive({flexDirection: 'column',paddingTop:'20vh',})};
`

export const AboutLeft= styled(motion.div)`
display:flex;
justify-content:center;
align-items:center;
width:50vw;
${Responsive({width:'90vw'})};
`

export const CardContainer = styled(motion.div)`
    display:flex;
    flex-direction:column;
    background-color:#282c34;
    color:white;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    width:400px;
    height:400px;
    padding:20px;
    align-items:center;
${Responsive({height:'95%',width:'92%', maxWidth:'500px'})};
`

export const AboutRight= styled(motion.div)`
display:flex;
justify-content:center;
width:50vw; 
${Responsive({width:'90vw'})};

`

export const MyPic= styled(motion.img)`
height:100%;
${Responsive({display:'none'})};
`

export const MyInfoTitle= styled(motion.h1)`
text-align:center;

`

export const MyInfoP= styled(motion.p)`
text-align:left;
color:grey;
white-space: wrap; 
  width: 95%; 
  overflow: hidden;
  text-overflow: ellipsis; 

`
