import styled from 'styled-components'
import {motion} from 'framer-motion'



export const AppContainer = styled(motion.div)`
color:white;
position: relative;
height:100vh;
overflow: hidden;
`

