import {
  Container,
  Wrapper,
  Front,
  Tech,
  FrontTitle,
  ButtonsDiv,
  GithubButton,
  LinkButton,
  ImageMain,
  TechContainer,
  TechTitle,
  TechP,
  SkillsContainer,
  Skill,
} from "./Hermes.styled";

import hermesPro from "../../images/hermesPro.webp";



// Home Variants
const homeVariant ={
  initial:{
    y:'140vw'
  },
  after:{
    y:0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
    },
  },
  exit:{
    y: '-140vh',
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}


// Home Variants
const picVariant ={
  initial:{
    opacity:0
  },
  after:{

    opacity: 1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay:2
    },
  },
  exit:{
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}




// Home Variants
const leftVariant ={
  initial:{
    x:'-100vw'
  },
  after:{
    x:0,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay:2
    },
  },
  exit:{
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}

// Home Variants
const rightVariant ={
  initial:{
    x:'100vw'
  },
  after:{
    x:0,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay:2
    },
  },
  exit:{
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}


// Home Variants
const buttonVariant ={
  initial:{
    scale:0
  },
  after:{
    scale:1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay:2
    },
  },
  exit:{
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}


const Hermes = () => {
  return (
    <Container initial='initial' animate='after' variants={homeVariant} exit='exit'>
      <Wrapper>
        <Front>
          <FrontTitle initial='initial' animate='after' variants={picVariant} exit='exit'>Hermes</FrontTitle>
          <ImageMain src={hermesPro} alt="sv house of fashion" />
          <ButtonsDiv>
            <GithubButton initial='initial' animate='after' variants={buttonVariant} whileHover={{ scale: 1.2 }} whileTap={{ scale: 1 }}>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="https://github.com/GobiShanthan/Hermes-Messenger-App"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub
              </a>
            </GithubButton>
            <LinkButton initial='initial' animate='after' variants={buttonVariant} whileHover={{ scale: 1.2 }} whileTap={{ scale: 1 }}>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="https://hermes-messenger01.herokuapp.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Live Link
              </a>
            </LinkButton>
          </ButtonsDiv>
        </Front>
        <Tech>
          <TechContainer initial='initial' animate='after' variants={leftVariant}>
          <TechTitle style={{paddingTop:'5vh'}}>Tools Used </TechTitle>
            <SkillsContainer>
              <Skill>HTML5</Skill>
              <Skill>CSS3</Skill>
              <Skill>JavaScript</Skill>
              <Skill>Python</Skill>
              <Skill>Django</Skill>
              <Skill>WebSockets</Skill>
              <Skill>PostgreSQL</Skill>
              <Skill>Redis</Skill>
              <Skill>Heroku</Skill>
              <Skill>AWS S3</Skill>


            </SkillsContainer>
          </TechContainer>
          <TechContainer style={{paddingBottom:'20vh'}}>
            <TechTitle initial='initial' animate='after' variants={picVariant} exit='exit'>Project OverView </TechTitle>
            <TechP initial='initial' animate='after' variants={rightVariant} >
              {" "}
              Hermes is a realtime instant chat application designed with Django
              and PostgreSQL. It is simple and easy to use, and it has a variety
              of features that make it a great choice for Chatting. Hermes is
              very user-friendly. The app also allows you to create groups, so
              you can easily chat with multiple people at once. This project was
              my first team project in the General Assembly Software Engineering
              Immersive course.
            </TechP>
            <TechP initial='initial' animate='after' variants={leftVariant} >
              {" "}
              There are a lot of moving parts in this project , and That's why
              we decided to use tools like figma and trello to help us keep
              track of everything. Figma is a great tool for wireframing because
              it allows you to see the big picture and how all the pieces fit
              together. And because it's web-based, it's easy for everyone on
              the team to access and make changes. Trello is also great for
              managing tasks, because you can easily assign them to specific
              people and set deadlines. Both of these tools helped us keep our
              project on track and allowed us to work according to Agile
              principles. We also created an Erd with lucid charts. This allowed
              us to see the relationships between different entities, and how
              they related with one another. We were then able to create a more
              comprehensive model that included all of the necessary
              information. The process was very helpful in understanding the
              data and how it could be used to create a better product.
            </TechP>
            <TechP initial='initial' animate='after' variants={rightVariant} >
              Since we had only a week to complete the project, as a group we
              decided to work on our MVP first. Our goal was to create a basic
              layout, but really showcase the realtime chat app. The chat aspect
              of the app was created with Websockets, Channels and Redis. This
              was my first time working with python and django. I was impressed
              at the amount and quality of documentation available for these
              technologies. It made it easy for me to get started and learn the
              basics and some advanced tech quickly.
            </TechP>
            <TechP initial='initial' animate='after' variants={leftVariant} >
              The hardest part of the app for me was implementing websockets. I
              had to set up a chat with the local hosting redis server and then
              change everything once I deployed to heroku. It was a lot of work,
              but it was worth it in the end. I started learning python a few
              months ago and I was really impressed with how versatile it is. I
              also found the syntax to be really intuitive and easy to
              understand.
            </TechP>
            <TechP initial='initial' animate='after' variants={rightVariant} >
              Overall, I was really happy with how Python and Django performed
              on this project and I will definitely be using it for future
              projects.
            </TechP>
          </TechContainer>
        </Tech>
      </Wrapper>
    </Container>
  );
};

export default Hermes;
