import {
  SvHouseContainer,
  SvHouseWrapper,
  SvHouseFront,
  SvHouseTech,
  FrontTitle,
  ButtonsDiv,
  LinkButton,
  ImageMain,
  TechContainer,
  TechTitle,
  TechP,
  SkillsContainer,
  Skill,
} from "./SvHouse.styled";

import svPro from "../../images/svPro.webp";

// Home Variants
const homeVariant = {
  initial: {
    y: "100vw",
  },
  after: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
    },
  },
  exit: {
    y: "-140vh",
    transition: {
      ease: "easeInOut",
      duration: 1,
      type: "spring",
    },
  },
};

// Home Variants
const picVariant = {
  initial: {
    opacity: 0,
  },
  after: {
    opacity: 1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay: 2,
    },
  },
  exit: {
    transition: {
      ease: "easeInOut",
      duration: 1,
      type: "spring",
    },
  },
};

// Home Variants
const leftVariant = {
  initial: {
    x: "-100vw",
  },
  after: {
    x: 0,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay: 2,
    },
  },
  exit: {
    transition: {
      ease: "easeInOut",
      duration: 1,
      type: "spring",
    },
  },
};

// Home Variants
const rightVariant = {
  initial: {
    x: "100vw",
  },
  after: {
    x: 0,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay: 2,
    },
  },
  exit: {
    transition: {
      ease: "easeInOut",
      duration: 1,
      type: "spring",
    },
  },
};

// Home Variants
const buttonVariant = {
  initial: {
    scale: 0,
  },
  after: {
    scale: 1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay: 2,
    },
  },
  exit: {
    transition: {
      ease: "easeInOut",
      duration: 1,
      type: "spring",
    },
  },
};

const SvHouse = () => {
  return (
    <SvHouseContainer
      initial="initial"
      animate="after"
      variants={homeVariant}
      exit="exit"
    >
      <SvHouseWrapper>
        <SvHouseFront>
          <FrontTitle
            initial="initial"
            animate="after"
            variants={picVariant}
            exit="exit"
          >
            SV HOUSE OF FASHION
          </FrontTitle>
          <ImageMain src={svPro} alt="sv house of fashion" />
          <ButtonsDiv>
            <LinkButton
              initial="initial"
              animate="after"
              variants={buttonVariant}
              whileHover={{ scale: 1.2 }}
              whileTap={{ scale: 1 }}
            >
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="https://www.svhouseoffashion.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Live Link
              </a>
            </LinkButton>
          </ButtonsDiv>
        </SvHouseFront>
        <SvHouseTech>
          <TechContainer
            initial="initial"
            animate="after"
            variants={leftVariant}
          >
            <TechTitle style={{ paddingTop: "5vh" }}>Tools Used </TechTitle>
            <SkillsContainer>
              <Skill>HTML5</Skill>
              <Skill>CSS3</Skill>
              <Skill>JavaScript</Skill>
              <Skill>REACT</Skill>
              <Skill>REDUX</Skill>
              <Skill>Styled-Components</Skill>
              <Skill>Framer-Motion</Skill>
              <Skill>Express</Skill>
              <Skill>MongoDB</Skill>
              <Skill>Mongoose</Skill>
              <Skill>Axios</Skill>
              <Skill>Stripe</Skill>
              <Skill>FireBase</Skill>
              <Skill>Formik</Skill>
              <Skill>Yup</Skill>
              <Skill>JSON Web Token</Skill>
              <Skill>BcryptJs</Skill>
            </SkillsContainer>
          </TechContainer>
          <TechContainer style={{ paddingBottom: "20vh", paddingTop: "5vh" }}>
            <TechTitle
              initial="initial"
              animate="after"
              variants={picVariant}
              exit="exit"
            >
              Project OverView{" "}
            </TechTitle>
            <TechP initial="initial" animate="after" variants={rightVariant}>
              I have been working on SV House of Fashion for WebFxNow. The site
              has an intuitive content management system where products are
              managed with CRUD capabilities. This makes it easy for the owner
              to keep track of products and ensure that they are up-to-date.
              Additionally, the system allows the owner to add new products
              easily and quickly.
            </TechP>

            <TechP initial="initial" animate="after" variants={rightVariant}>
              {" "}
              This App was also the first time I utilized React.lazy and
              Suspense to get performance increases. The app loads faster
              because code is split into smaller chunks. When a user clicks on a
              link, they are only fetching the code they need, not loading the
              entire application. This makes the application more responsive and
              users are less likely to experience lag. I'm excited to continue
              using this technology to make my applications more performant!
            </TechP>
            <TechP initial="initial" animate="after" variants={leftVariant}>
              I was tasked with optimizing the SEO and accessibility and
              performance of the website. After doing some research, I decided
              to use Lighthouse to help me with this. I was able to get both the
              SEO and accessibility score up to 100 percent. I also got the
              performance score to 94 percent, which was a monumental increase
              from its intial 43 percent. This was a huge accomplishment for me
              and I was very proud of myself.
            </TechP>

            <TechP initial="initial" animate="after" variants={rightVariant}>
              {" "}
              I used a variety of techniques to improve the website's ranking in
              search engines and to make it more accessible for users. I
              implemented proper keyword research, meta tags, and other on-page
              optimization techniques. I also made sure the website's design was
              responsive and that all content was easy to read and understand.
              Overall, I'm extremely happy with the results I was able to
              achieve. The website is now ranking well in search engines and is
              much more accessible for users. Overall, I am really happy with
              the progress I have made on this project so far. I am confident
              that I will be able to complete it within the specified timeframe.
            </TechP>
          </TechContainer>
        </SvHouseTech>
      </SvHouseWrapper>
    </SvHouseContainer>
  );
};

export default SvHouse;
