import {
  FormContainer,
  ContactButton,
  ContactInput,
  EmailPic
} from "./ContactForm.styled";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import emailSent from '../../images/emailSent.gif'

const ContactForm = () => {
  const [success, setSuccess] = useState(false);

  const form = useRef();

  const cardVartiant = {
    initial: {
      x: "100vw",
    },
    after: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        duration: 2.5,
        ease: "easeInOut",
      },
    },
    exit: {
      y: "100vh",
      transition: {
        ease: "easeInOut",
        duration: 1,
        type: "spring",
      },
    },
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9t27ptd",
        "template_2a7nhwf",
        form.current,
        "usxxO-ufdu_GkNuUI"
      )
      .then(
        (result) => {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 5000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <FormContainer
      variants={cardVartiant}
      initial="initial"
      animate="after"
      exit="exit"
      ref={form}
      onSubmit={sendEmail}
    >
      {success ? (
        <><h1>
          Email Sent
        </h1>
        <EmailPic src={emailSent} />
        </>
        
      ) : (
        <>
          <h1>CONTACT FORM</h1>
          <ContactInput placeholder="Name" name="user_name" required />
          <ContactInput placeholder="Phone Number" name="number" required />
          <ContactInput
            placeholder="Email"
            type="email"
            required
            name="user_email"
          />
          <textarea
            name="message"
            style={{
              maxWidth: "300px",
              backgroundColor: "lightgrey",
              color: "black",
            }}
          ></textarea>
          <ContactButton
            type="submit"
            value="Send"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 1 }}
          >
            SEND MESSAGE
          </ContactButton>
        </>
      )}
    </FormContainer>
  );
};

export default ContactForm;
