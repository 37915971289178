import {
  Container,
  Wrapper,
  Front,
  Tech,
  FrontTitle,
  ButtonsDiv,
  GithubButton,
  LinkButton,
  ImageMain,
  TechContainer,
  TechTitle,
  TechP,
  SkillsContainer,
  Skill,
} from "./EventHandlers.styled";

import eventPro from "../../images/eventPro.webp";
// Home Variants
const homeVariant ={
  initial:{
    y:'140vw'
  },
  after:{
    y:0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
    },
  },
  exit:{
    y: '-140vh',
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}


// Home Variants
const picVariant ={
  initial:{
    opacity:0
  },
  after:{

    opacity: 1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay:2
    },
  },
  exit:{
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}




// Home Variants
const leftVariant ={
  initial:{
    x:'-100vw'
  },
  after:{
    x:0,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay:2
    },
  },
  exit:{
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}

// Home Variants
const rightVariant ={
  initial:{
    x:'100vw'
  },
  after:{
    x:0,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay:2
    },
  },
  exit:{
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}


// Home Variants
const buttonVariant ={
  initial:{
    scale:0
  },
  after:{
    scale:1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay:2
    },
  },
  exit:{
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}



const EventHandlers = () => {
  return (
    <Container initial='initial' animate='after' variants={homeVariant} exit='exit'>
      <Wrapper>
        <Front>
          <FrontTitle  initial='initial' animate='after' variants={picVariant} exit='exit'>EventHandlers</FrontTitle>
          <ImageMain src={eventPro} alt="sv house of fashion" />
          <ButtonsDiv>
            <GithubButton initial='initial' animate='after' variants={buttonVariant} whileHover={{ scale: 1.2 }} whileTap={{ scale: 1 }}>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="https://github.com/GobiShanthan/eventhandlers"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub
              </a>
            </GithubButton>
            <LinkButton initial='initial' animate='after' variants={buttonVariant} whileHover={{ scale: 1.2 }} whileTap={{ scale: 1 }}>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="https://eventhandlers8.onrender.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Live Link
              </a>
            </LinkButton>
          </ButtonsDiv>
        </Front>
        <Tech>
          <TechContainer initial='initial' animate='after' variants={leftVariant}>
          <TechTitle style={{paddingTop:'5vh'}}>Tools Used </TechTitle>
            <SkillsContainer>
              <Skill>HTML5</Skill>
              <Skill>CSS3</Skill>
              <Skill>JavaScript</Skill>
              <Skill>REACT</Skill>
              <Skill>REDUX</Skill>
              <Skill>Styled-Components</Skill>
              <Skill>Framer-Motion</Skill>
              <Skill>Express</Skill>
              <Skill>MongoDB</Skill>
              <Skill>Mongoose</Skill>
              <Skill>Stripe</Skill>
              <Skill>FireBase</Skill>
              <Skill>Formik</Skill>
              <Skill>Yup</Skill>
              <Skill>JSON Web Token</Skill>
              <Skill>BcryptJs</Skill>
              <Skill>Socket.IO</Skill>
            </SkillsContainer>
          </TechContainer>
          <TechContainer style={{paddingBottom:'20vh'}}>
            <TechTitle initial='initial' animate='after' variants={picVariant} exit='exit'>Project OverView </TechTitle>
            <TechP initial='initial' animate='after' variants={rightVariant} >
              In a week, my team of four and I created a mobile friendly MERN
              app. This app was created for a General assembly project. The app
              is designed to be user-friendly and easy to navigate.
            </TechP>
            <TechP initial='initial' animate='after' variants={leftVariant} >
              {" "}
              We used Trello to manage our app. Lucid charts to design our ERDs
              and Figma to create the wireframe as a group. It was a great
              experience being able to work together as a team and utilize
              different tools to help us create a successful product. I learned
              a lot about project management and how important it is to have
              clear and concise goals when working on something as large as an
              app.{" "}
            </TechP>
            <TechP initial='initial' animate='after' variants={rightVariant} >
              {" "}
              This project was all about collaboration for me more than
              anything. I was the Github manager and learned a lot about Github
              and solving merge conflicts.
            </TechP>
            <TechP initial='initial' animate='after' variants={leftVariant} >
              My favorite part of this app is the backend express. I followed
              the Fat Models and skinny controllers principle when creating my
              backend express applications. This means that my models are very
              robust and my controllers are very lean. This makes for a very
              fast and efficient application.
            </TechP>

            <TechP initial='initial' animate='after' variants={rightVariant} >
              {" "}
              I learned a lot about MVC design and team collaboration with this
              project. I was able to see how different people worked together to
              create a cohesive final product. I also saw how important it was
              to communicate effectively in order to avoid any
              misunderstandings. Overall, I'm glad I participated in this
              project as it was a great learning experience.
            </TechP>
          </TechContainer>
        </Tech>
      </Wrapper>
    </Container>
  );
};

export default EventHandlers;
