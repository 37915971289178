
import {
  HomeContainer,
  TitleContainer,
  TitleName,
  Ptag
} from './Home.styled'





// Home Variants
const homeVariant ={
  initial:{
    y:'100vw'
  },
  after:{
    y:0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
    },
  },
  exit:{
    y: '-100vh',
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}






const Home = () => {

  return (

    <HomeContainer initial='initial' animate='after' variants={homeVariant} exit='exit' >
      <TitleContainer>
        <Ptag >I am </Ptag>
        <TitleName>GOBI</TitleName>
        <Ptag style={{textAlign: 'right'}}>A Software Developer</Ptag>
      </TitleContainer>
    </HomeContainer>

  )
}

export default Home