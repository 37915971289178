import styled from 'styled-components'
import {motion} from 'framer-motion'


export const MenuContainer = styled(motion.div)`
display:flex;
color:white;
justify-content:space-between;
position:fixed;
width:100vw;
z-index:99;
`

export const LogoContainer = styled(motion.div)`
display:flex;
color:white;
padding:15px;

`

export const LinksContainer = styled(motion.div)`
display:flex;
color:white;
padding:15px;
gap:10px;
`


export const LogoImg= styled(motion.img)`
width:35px;
height:35px;
padding:20px;

`

export const MenuLink= styled(motion.h5)`
`