
import {
    SkillsContainer,
    TitleContainer,
    TitleName,
    IconsContainer,
    Icons
  } from './Skills.styled'
  
  import px1 from '../../images/px-1.webp'
  import px2 from '../../images/px-2.webp'
  import px3 from '../../images/px-3.webp'
  import px4 from '../../images/px-4.webp'
  import px5 from '../../images/px-5.webp'
  import px6 from '../../images/px-6.webp'
  import px7 from '../../images/px-7.webp'
  import px8 from '../../images/px-8.webp'
  import px9 from '../../images/px-9.webp'
  import px10 from '../../images/px-10.webp'
  import px11 from '../../images/px-11.webp'
  import px12 from '../../images/px-12.webp'
  import px13 from '../../images/px-13.webp'
  import px14 from '../../images/px-14.webp'
  import px15 from '../../images/px-15.webp'
  import px16 from '../../images/px-16.webp'
  import px17 from '../../images/px-17.webp'
  import px18 from '../../images/px-18.webp'
  import px19 from '../../images/px-19.webp'
  import px20 from '../../images/px-20.webp'
  import px21 from '../../images/px-21.webp'
  import px22 from '../../images/px-22.webp'
  

  
  const getRandomDelay = () => -(Math.random() * 0.7 + 0.05);
  
  const randomDuration = () => Math.random() * 0.07 + 0.23;
  

  
  
  
  // Home Variants
  const SkillsVariant ={
    initial: {
      scale:0,
    },
    after: {
      scale:1,
      opacity: 1,
      transition: {
        when: "afterChildren",
        duration:2.5
      },
    },
    exit:{
      y: '-100vh',
      transition: {
          ease: 'easeInOut',
          duration: 1,
          type: "spring",
      },
    }
  }
  
  
  const IconsVariant = {
    initial :{
  },
  after: (i) => ({
    rotate: i % 2 === 0 ? [-1, 1.3, 0] : [1, -1.4, 0],
    transition: {
      delay: getRandomDelay(),
      repeat: Infinity,
      duration: randomDuration()
    }
  }),
  };
  
  
  
  const Skills = () => {
  
    return (
  
      <SkillsContainer initial='initial' animate='after' variants={SkillsVariant} exit='exit' >
        <TitleContainer>
          <TitleName > My Skills</TitleName>
          <IconsContainer>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px1}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px2}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px3}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px4}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px5}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px6}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px7}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px8}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px9}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px10}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px11}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px12}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px13}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px14}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px15}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px16}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px17}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px18}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px19}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px20}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px21}/>
            <Icons initial='initial' animate='after' variants={IconsVariant} src={px22}/>
          </IconsContainer>
        </TitleContainer>
      </SkillsContainer>
  
    )
  }
  
  export default Skills