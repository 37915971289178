import styled from 'styled-components'
import {motion} from 'framer-motion'
import {Responsive} from '../../responsive'


export const SkillsContainer = styled(motion.div)`
display:flex;
color:white;
position: relative;
height:100vh;
overflow: hidden;

`

export const TitleContainer = styled(motion.div)`
 position: absolute;
    top: 50%;  
    left: 50%; 
    transform: translate(-50%, -50%); 
`
export const Ptag = styled(motion.p)`

`


export const ScrollImg= styled(motion.img)`
width:25px;
height:25px;
position:absolute;
bottom:0;
padding:20px;

`


export const TitleName = styled(motion.div)`
text-align:center;
padding:50px;
font-size:4vw;
font-weight: 900;
${Responsive({fontSize:'10vw',textAlign:'center',padding:'20px'})};
`

export const IconsContainer = styled(motion.div)`
display:flex;
flex-wrap:wrap;
max-width:600px;
max-height:1100px;
justify-content: center;
`

export const Icons = styled(motion.img)`
width:100px;
height:100px;
${Responsive({width:'15vw',height:'15vw'})};
`
