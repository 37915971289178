import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
  background-color: transparent;
  height: 100vh;
  padding-top: 8vh;
`;

export const Wrapper = styled(motion.div)`
  background-color: transparent;
  height: 100%;
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
     -webkit-appearance: none;
     display: none;
  }
`;


////////////////////////////////////////////////////////////////
//FrontPage
export const Front = styled(motion.div)`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const FrontTitle = styled(motion.h1)``;

export const ButtonsDiv = styled(motion.div)`
  margin-top: 4vh;
  display: flex;
  gap:5px;
`;
export const GithubButton = styled(motion.button)`
border-radius:5px;
background-color:#282c34;
color:white;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
padding:15px;
border:none;
`;

export const LinkButton = styled(motion.button)`
border-radius:5px;
background-color:#282c34;
color:white;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
padding:15px;
border:none;
`;


export const ImageMain = styled(motion.img)`
  max-width: 500px;
  width: 95%;
`;

/////////////////////////////////////////////////////////////////
//TechPage
export const Tech = styled(motion.div)`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TechContainer = styled(motion.div)`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100%;
  padding-bottom:10vh;

`;

export const TechTitle = styled(motion.h1)`
`;

export const TechP = styled(motion.p)`
max-width:500px;
`;

export const SkillsContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  max-width:500px;
  
`;

export const Skill = styled(motion.div)`
  background-color: grey;
  padding: 10px;
  border-radius: 5px;
  color: white;
`;


export const InfoContainer = styled(motion.div)`
  display:flex;
  width: 100%;
  height:100%;
  max-width:500px;
`;
export const InfoLeft = styled(motion.div)`
  display:flex;

  width: 100%;

`;
export const InfoRight = styled(motion.div)`
  display:flex;

  width: 100%;
  justify-content:center;
`;

export const InfoText = styled(motion.p)`
  
`
export const InfoImage = styled(motion.img)`
object-fit: cover;
width: 90%;
height: 250px;
`