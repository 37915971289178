import styled from 'styled-components'
import {motion} from 'framer-motion'



export const HomeContainer = styled(motion.div)`
display:flex;
color:white;
position: relative;
height:100vh;
overflow: hidden;
`

export const TitleContainer = styled(motion.div)`
 position: absolute;
    top: 50%;  
    left: 50%; 
    transform: translate(-50%, -50%); 
`
export const Ptag = styled(motion.p)`

`


export const ScrollImg= styled(motion.img)`
width:25px;
height:25px;
position:absolute;
bottom:0;
padding:20px;

`


export const TitleName = styled(motion.div)`
font-size:13vw;
font-weight: 900;
`
