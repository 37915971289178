import {css} from 'styled-components'




////////WIDE RESONSIVE
//MOBILE  UP TO IPHONE 10 SIZE 414 WIDE
export const Responsive =(props)=>{
    return css`
    @media only screen and (max-width:1195px){
        ${props}
    }
    `
}

