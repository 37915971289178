import {
    ContactContainer,
    ContactWrapper,
    ContactRight,
} from './Contact.styled'

import ContactForm from '../../components/ContactForm/ContactForm'



const Contact = () => {


 



  return (
    <ContactContainer>
        <ContactWrapper>
            <ContactRight>
                <ContactForm  />
            </ContactRight>
        </ContactWrapper>
    </ContactContainer>
  )
}

export default Contact