import {
  Container,
  Wrapper,
  Front,
  Tech,
  FrontTitle,
  ButtonsDiv,
  GithubButton,
  LinkButton,
  ImageMain,
  TechContainer,
  TechTitle,
  TechP,
  SkillsContainer,
  Skill,
} from "./PartyCode.styled";

import partyCode from "../../images/partyPro.webp";



// Home Variants
const homeVariant ={
  initial:{
    y:'140vw'
  },
  after:{
    y:0,
    opacity: 1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
    },
  },
  exit:{
    y: '-140vh',
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}


// Home Variants
const picVariant ={
  initial:{
    opacity:0
  },
  after:{

    opacity: 1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay:2
    },
  },
  exit:{
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}




// Home Variants
const leftVariant ={
  initial:{
    x:'-100vw'
  },
  after:{
    x:0,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay:2
    },
  },
  exit:{
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}

// Home Variants
const rightVariant ={
  initial:{
    x:'100vw'
  },
  after:{
    x:0,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay:2
    },
  },
  exit:{
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}


// Home Variants
const buttonVariant ={
  initial:{
    scale:0
  },
  after:{
    scale:1,
    transition: {
      type: "spring",
      duration: 2.5,
      ease: "easeInOut",
      delay:2
    },
  },
  exit:{
    transition: {
        ease: 'easeInOut',
        duration: 1,
        type: "spring",
    },
  }
}









const PartyCode = () => {
  return (
    <Container initial='initial' animate='after' variants={homeVariant} exit='exit'>
      <Wrapper>
        <Front>
          <FrontTitle initial='initial' animate='after' variants={picVariant} exit='exit'>Party Code</FrontTitle>
          <ImageMain src={partyCode} alt="partycode" />
          <ButtonsDiv>
            <GithubButton initial='initial' animate='after' variants={buttonVariant}  whileHover={{ scale: 1.2 }} whileTap={{ scale: 1 }}>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="https://github.com/GobiShanthan/MEN-STACK"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub
              </a>
            </GithubButton>
            <LinkButton initial='initial' animate='after' variants={buttonVariant}  whileHover={{ scale: 1.2 }} whileTap={{ scale: 1 }}>
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="https://party-code.onrender.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Live Link
              </a>
            </LinkButton>
          </ButtonsDiv>
        </Front>
        <Tech>
          <TechContainer initial='initial' animate='after' variants={leftVariant} >
          <TechTitle  style={{paddingTop:'5vh'}}>Tools Used </TechTitle>
            <SkillsContainer>
              <Skill>HTML5</Skill>
              <Skill>CSS3</Skill>
              <Skill>JavaScript</Skill>
              <Skill>Express</Skill>
              <Skill>MongoDB</Skill>
              <Skill>Mongoose</Skill>
              <Skill>Imgur Api</Skill>
              <Skill>Google OAuth2</Skill>
            </SkillsContainer>
          </TechContainer>
          <TechContainer style={{paddingTop:'8vh'}}>
            <TechTitle initial='initial' animate='after' variants={picVariant} exit='exit'>Project OverView </TechTitle>
            <TechP initial='initial' animate='after' variants={rightVariant}>
              Party Code is a MEN stack app. I created Party Code in one week as
              a project for my software engineering class at the General
              Assembly. It's designed for people to make parties, find parties
              in your area, and even sell tickets to the parties. The app is
              easy to use and utilizes Passport strategy for authenticating with
              Google using the OAuth 2.0 API. Working on this app was crucial
              for me to understand the role that front end frameworks like React
              play.
            </TechP>
            <TechP initial='initial' animate='after' variants={leftVariant}>
              The entire styling of this project was done through html and css
              and all the elements were created using GIMP and canva. The most
              challenging part has been making sure all the elements fit
              together nicely. But I think it's all coming together and I am
              currently working on my icebox features, such as payment and maps
              integration.
            </TechP>

          </TechContainer>
        </Tech>
      </Wrapper>
    </Container>
  );
};

export default PartyCode;
