import {Routes,Route,Navigate,useLocation} from 'react-router-dom'

import {AnimatePresence} from 'framer-motion'
import {AppContainer} from "./App.styled"



import ContactMenu from '../../components/ContactMenu/ContactMenu';
import Menu from '../../components/Menu/Menu'
import Home from '../Home/Home'
import About from '../About/About'
import Projects from '../Projects/Projects'
import Contact from '../Contact/Contact'
import SvHouse from '../SvHouse/SvHouse'
import PizzaNeo from '../PizzaNeo/PizzaNeo'
import PartyCode from '../PartCode/PartyCode'
import Hermes from '../Hermes/Hermes'
import EventHandlers from '../EventHandlers/EventHandlers'
import Skills from '../Skills/Skills'


function App() {
  let location = useLocation();

  return (
    <AppContainer  className="App">
      <Menu/>
      <ContactMenu/>
      <AnimatePresence mode='wait'>
      <Routes location={location} key={location.key} >
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/projects' element={<Projects/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/svhouse' element={<SvHouse/>}/>
        <Route path='/pizza' element={<PizzaNeo/>}/>
        <Route path='/eventhandlers' element={<EventHandlers/>}/>
        <Route path='/hermes' element={<Hermes/>}/>
        <Route path='/partycode' element={<PartyCode/>}/>
        <Route path='/skills' element={<Skills/>}/>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      </AnimatePresence>
    </AppContainer>
  );
}

export default App;
