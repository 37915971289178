import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./Carousel.css";

//PICTURES
import svPro from "../../images/svPro.webp";
import partyPro from "../../images/partyPro.webp";
import hermesPro from "../../images/hermesPro.webp";
import pizzaPro from "../../images/pizzaPro.webp";
import eventPro from "../../images/eventPro.webp";

//STYLED COMPONENTS
import {
  SlideContainer,
  SlideImage,
  SlideButton,
} from "../Carousel/Carousel.styled";

// import required modules
import { EffectCoverflow, Pagination } from "swiper";

export default function App() {
  return (
    <>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1.1,
          slideShadows: false,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <SlideImage src={svPro} />

          <SlideContainer>
            <h1>SV House Of Fashion</h1>
            <p>
              Mobile Friendly Full Stack MERN App with email password reset
            </p>
            <Link
              style={{
                color: "white",
                textDecoration: "none",
                cursor: "pointer",
              }}
              to="/svhouse"
            >
              <SlideButton whileHover={{ scale: 1.2 }} whileTap={{ scale: 1 }}>
                Learn More
              </SlideButton>
            </Link>
          </SlideContainer>
        </SwiperSlide>
        <SwiperSlide>
          <SlideImage src={partyPro} />
          <SlideContainer>
            <h1>Party Code </h1>
            <p>Men Stack Ticket Booking App, with Google OAuth2 </p>

            <Link
              style={{
                color: "white",
                textDecoration: "none",
                cursor: "pointer",
              }}
              to="/partycode"
            >
              <SlideButton whileHover={{ scale: 1.2 }} whileTap={{ scale: 1 }}>
                Learn More
              </SlideButton>
            </Link>
          </SlideContainer>
        </SwiperSlide>
        <SwiperSlide>
          <SlideImage src={hermesPro} />
          <SlideContainer>
            <h1>Hermes Messenger </h1>
            <p>Django Realtime Chat App with Web Sockets & PostgreSQL </p>

            <Link
              style={{
                color: "white",
                textDecoration: "none",
                cursor: "pointer",
              }}
              to="/hermes"
            >
              <SlideButton whileHover={{ scale: 1.2 }} whileTap={{ scale: 1 }}>
                Learn More
              </SlideButton>
            </Link>
          </SlideContainer>
        </SwiperSlide>
        <SwiperSlide>
          <SlideImage src={pizzaPro} />
          <SlideContainer>
            <h1>Pizza Neapolitan </h1>
            <p>Mobile Friendly MERN Stack App with Socket.IO</p>
            <Link
              style={{
                color: "white",
                textDecoration: "none",
                cursor: "pointer",
              }}
              to="/pizza"
            >
              <SlideButton whileHover={{ scale: 1.2 }} whileTap={{ scale: 1 }}>
                Learn More
              </SlideButton>
            </Link>
          </SlideContainer>
        </SwiperSlide>
        <SwiperSlide>
          <SlideImage src={eventPro} />
          <SlideContainer>
            <h1>Event Handlers </h1>
            <p>Mobile Friendly MERN Stack App with Leaflet and Socket.IO</p>

            <Link
              style={{
                color: "white",
                textDecoration: "none",
                cursor: "pointer",
              }}
              to="/eventhandlers"
            >
              <SlideButton whileHover={{ scale: 1.2 }} whileTap={{ scale: 1 }}>
                Learn More
              </SlideButton>
            </Link>
          </SlideContainer>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
